<div class="login">
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 h-screen">
    <div
      class="
        col-span-1
        md:col-span-1
        lg:col-span-2
        p-10
        md:block
        bg-gray-100
        hidden
      "
    >
      <a class="flex justify-between items-center" href="https://itaxvn.com">
        <img src="@/assets/icons/logo.svg" alt="" class="relative bottom-12 w-32 h-32" />
      </a>
      <div class="flex items-center justify-center">
        <img src="@/assets/login-image.svg" alt="photo" class="lg:p-20" />
      </div>
    </div>

    <div class="p-5 md:p-14 grid items-center">
      <!-- <div class="flex justify-end">
        <changeLanguage />
      </div> -->
      <div class="mt-4 px-5 grid items-center">
        <h3 class="font-500 welcome mb-2">{{$t("auth.login.wellcome")}}</h3>
        <p class="please text-base font-400 mb-5">
          {{$t("auth.login.please")}}
        </p>

        <div class="mt-6">
          <Form as="el-form" :validation-schema="login" @submit="signin">
            <label>Tên đăng nhập</label>
            <InputWithValidation
              label="Tên đăng nhập"
              v-model="formValues.name"
              name="ten"
              :autocomplete="true"
            />
            <div class="form-password mt-3">
              <div class="flex justify-between">
                <label>Mật khẩu</label>
                <div class="text-sm text-primary">
                  <router-link :to="{ name: 'forgotPassword' }">
                    {{$t("auth.login.forgotpassword")}}
                  </router-link>
                </div>
              </div>
            </div>

            <InputWithValidation
              type="password"
              label="Mật khẩu"
              v-model="formValues.password"
              name="matKhau"
              :autocomplete="true"
            />

            <vue-recaptcha
              v-if="isShowCaptcha"
              ref="recaptcha"
              :sitekey="captchaSiteID"
              loadRecaptchaScript="true"
              language="vi"
              @verify="verifyCaptcha"
            />

            <div class="signin mt-10">
              <button
                class="
                  bg-blue-500
                  hover:bg-blue-700
                  text-white
                  font-bold
                  py-2
                  px-4
                  rounded
                "
                style="width: 100%"
              >
                {{$t("auth.login.signin")}}
              </button>
            </div>

            <p class="create-account text-base mt-3">
              {{$t("auth.login.new")}}
              <span class="text-blue-500 hover:text-blue-700">
                <router-link :to="{ name: 'register' }">
                  {{$t("auth.login.createaccount")}}
                </router-link>
              </span>
            </p>

            <!-- <SSNLogin /> -->
          </Form>
        </div>
      </div>
    </div>
  </div>
</div>
